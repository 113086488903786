<template>
  <div>
    <v-row align="center">
      <v-col>
        <h1 class="headline font-weight-bold">{{ $t("favoritesPage.title") }}</h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        style="letter-spacing: 0"
        @click="showCreateDialog = true"
        class="text-capitalize"
        text
        ><div class="d-flex align-start py-1">
          <v-icon size="20">mdi-plus</v-icon> {{ $t("favoritesPage.createDialogTitle") }}
        </div></v-btn
      >
    </v-row>
    <v-divider></v-divider>
    <div v-if="!itemsLoading">
      <v-container style="padding-left: 0">
        <v-layout row wrap>
          <v-container>
            <v-row>
              <v-col cols="6" sm="6" md="4" lg="4">
                <DefaultItemsCard />
              </v-col>
              <v-col
                v-for="collection in collections"
                :key="collection.id"
                cols="6"
                sm="6"
                md="4"
                lg="4"
              >
                <!-- <item-card :item="item" /> -->
                <CollectionCard :collection="collection" />
              </v-col>
            </v-row>
          </v-container>
        </v-layout>
      </v-container>
      <!-- <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.lastPage"
          @input="getItems(true)"
          color="black"
        ></v-pagination>
      </div> -->
    </div>
    <!-- Loader -->
    <div v-if="itemsLoading" class="mt-16 pt-16">
      <v-row
        ><v-progress-circular
          indeterminate
          class="mx-auto"
          size="100"
        ></v-progress-circular
      ></v-row>
    </div>
    <!-- No content -->
    <!-- <no-content
      v-if="!itemsLoading && collections.length == 0"
      :title="$t('noContent.title')"
      :msg="$t('noContent.favorites')"
      icon="mdi-robot-confused"
    /> -->

    <CreateDialog
      v-if="showCreateDialog"
      :dialog="showCreateDialog"
      @close="showCreateDialog = false"
      @done="getCollections"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Favorites",
  components: {
    CollectionCard: () => import("./Components/CollectionCard.vue"),
    DefaultItemsCard: () => import("./Components/DefaultCollection.vue"),
    CreateDialog: () => import("./Components/CreateCollection.vue"),
    // NoContent: () => import("../../components/NoContent.vue"),
  },
  data() {
    return {
      itemsLoading: false,
      showCreateDialog: false,
    };
  },
  computed: {
    ...mapState({
      collections: (state) => state.favoritesModule.collections,
      defaultCount: (state) => state.favoritesModule.defaultCount,
    }),
  },
  created() {
    this.getCollections(false);
  },
  methods: {
    getCollections() {
      this.showCreateDialog = false;

      this.itemsLoading = true;
      this.$store
        .dispatch("favoritesModule/getCollections", {})
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
  },
};
</script>
